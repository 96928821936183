<template>
    <div class="password-login">
        <img
            v-if="templateInfo.loginMethod.find(e => ['2', '3'].includes(e))"
            class="qrcode-btn"
            :src="imgQrcodeBtn"
            alt="qrcodeBtn"
            @click="handleLoginMethodSwitch('2')"
        >
        <div class="login-header">
            <div
                v-if="templateInfo.loginMethod.filter(e => ['1', '4'].includes(e)).length === 1"
                class="login-title_welcome"
            >欢迎登录</div>
            <template v-else>
                <div class="login-title login-title_active">密码登录</div>
                <div class="login-title" @click="handleLoginMethodSwitch('4')">验证码登录</div>
            </template>
        </div>
        <el-form ref="loginForm" class="login-form" :model="loginForm" :rules="loginRules" label-width="0px">
            <!-- 账号 -->
            <el-form-item prop="userName">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="loginForm.userName" placeholder="账号"></el-input>
                </div>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-lock"></i>
                    <el-input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="loginForm.password"
                        placeholder="密码"
                        @keyup.enter.native="handleLogin"
                    >
                        <i slot="suffix" @click="switchPassword">
                            <img
                                v-if="showPassword"
                                :src="showView"
                                class="input-icon"
                                alt="show"/>
                            <img
                                v-else
                                :src="closeView"
                                class="input-icon"
                                alt="close"/>
                        </i>
                    </el-input>
                </div>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item prop="code">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-postcard"></i>
                    <el-input
                        v-model="loginForm.code"
                        placeholder="验证码"
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <!-- 网络图片验证码 -->
                    <img
                        v-if="loginForm.codeUrl"
                        slot="suffix"
                        class="login-form-item-code"
                        :src="loginForm.codeUrl"
                        alt="code"
                        @click="handlePictureVerificationCodeClick"
                    >
                    <!-- 静态图片验证码占位 -->
                    <img
                        v-else
                        slot="suffix"
                        class="login-form-item-code"
                        :src="imgVerificationCode"
                        alt="code"
                    >
                </div>
            </el-form-item>
            <!-- 记住密码 -->
            <div class="remember-password">
                <el-checkbox v-model="loginForm.rememberPassword" v-if="templateInfo.rememberme == '1'">记住登录状态</el-checkbox>
                <span v-else></span>
                <el-button type="text" class="text" @click="forgottenPassword">忘记密码</el-button>
            </div>
            <!-- 登录按钮 -->
            <div
                class="login-button"
                :class="{ 'login-button_loading': loginButtonLoading }"
                @click="handleLogin">
                <i v-show="loginButtonLoading" class="el-icon-loading"></i>
                <span>登  录</span>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'PasswordLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            codeUrl: '',
            showPassword: false,
        }
    },
    computed: {
        imgQrcodeBtn() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_btn.png')
        },
        imgVerificationCode() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_verification_code.png')
        },
        closeView () {
            return require('@/assets/images/login/close-view.png')
        },
        showView () {
            return require('@/assets/images/login/show-view.png')
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 刷新验证码图片
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        handleLoginMethodSwitch(loginMethod) {
            this.$eventDispatch('loginMethodSwitch', loginMethod);
        },
        handlePictureVerificationCodeClick() {
            this.$eventDispatch('pictureVerificationCodeClick', this.loginForm.codeUrl);
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                // console.log("[handleLogin]valid:", valid)
                if (valid) {
                    this.$eventDispatch('login', this.loginForm);
                }
            })
        },
        forgottenPassword () {
            this.$emit('forgottenPassword')
        },
        switchPassword () {
            this.showPassword = !this.showPassword;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.password-login {
    position: relative;
    width: 100%;
    height: 100%;

    .qrcode-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100 * #{$coefficient});
        height: calc(100 * #{$coefficient});
        object-fit: contain;
        display: inline-block;
        cursor: pointer;
    }

    .login-header {
        padding: calc(36 * #{$coefficient}) 0 calc(40 * #{$coefficient}) 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .login-title_welcome {
            font-size: calc(40 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #427AFA;
        }

        .login-title {
            font-size: calc(22 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            cursor: pointer;

            +.login-title {
                margin-left: calc(36 * #{$coefficient});
            }
        }
        .login-title_active {
            font-size: calc(28 * #{$coefficient});
            color: #427AFA;
        }
    }

    .login-form {
        padding: 0 calc(41 * #{$coefficient});

        ::v-deep .el-form-item {
            margin-bottom: 0;

            +.el-form-item {
                margin-top: calc(24 * #{$coefficient});
            }

            .el-form-item__error {
                padding-top: calc(2 * #{$coefficient});
                font-size: calc(12 * #{$coefficient});
            }
        }

        ::v-deep .el-input {
            font-size: calc(18 * #{$coefficient});

            .el-input__inner {
                border: unset;
                padding: 0;
                height: auto;
                line-height: 1;
            }

            .el-input__suffix {
                .el-input__suffix-inner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                }

                .el-input__icon {
                    width: auto;
                    line-height: 1;
                    font-size: calc(16 * #{$coefficient});
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    +.el-input__icon {
                        margin-left: calc(10 * #{$coefficient});
                    }
                }

                .el-input__validateIcon {
                    display: none;
                }
            }
        }
        ::v-deep .el-input--suffix {
            .el-input__inner {
                padding-right: calc(36 * #{$coefficient});
            }
        }

        .login-form-item {
            display: flex;
            align-items: center;
            .input-icon {
                width: calc(21 * #{$coefficient});
                margin-top: calc(10 * #{$coefficient});
            }
        }

        .login-form-input {
            height: calc(56 * #{$coefficient});
            padding: 0 calc(20 * #{$coefficient}) 0;
            border: 1px solid #D9D9D9;
            border-radius: calc(8 * #{$coefficient});
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .login-form-input-icon {
                color: #C9C9C9;
                font-size: calc(22 * #{$coefficient});
                padding-right: calc(12 * #{$coefficient});
            }

            ::v-deep .el-input {
                // 解决回填后带背景色问题
                .el-input__inner:-webkit-autofill,
                .el-input__inner:-webkit-autofill:hover,
                .el-input__inner:-webkit-autofill:focus,
                .el-input__inner:-webkit-autofill:active {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out,
                    background-color 99999s ease-out;
                }
            }
        }
        .login-form-input:hover {
            border-color: #3C7FFF;
        }

        .login-form-item-code {
            width: calc(103 * #{$coefficient});
            height: calc(36 * #{$coefficient});
            object-fit: contain;
            display: inline-block;
            margin-left: calc(10 * #{$coefficient});
            cursor: pointer;
        }

        .remember-password {
            display: flex;
            justify-content: space-between;
            margin-top: calc(20 * #{$coefficient});
            line-height: 1;

            ::v-deep .el-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-checkbox__inner {
                    width: calc(16 * #{$coefficient});
                    height: calc(16 * #{$coefficient});
                    min-width: 9px;
                    min-height: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: calc(2 * #{$coefficient});
                        left: calc(5 * #{$coefficient});
                        width: calc(3 * #{$coefficient});
                        height: calc(7 * #{$coefficient});
                    }
                }
                .el-checkbox__label {
                    padding-left: calc(10 * #{$coefficient});
                    line-height: calc(19 * #{$coefficient});
                    font-size: calc(16 * #{$coefficient});
                }
            }

            .text {
                /deep/ span {
                    font-size: calc(16 * #{$coefficient});
                }
            }
        }

        .login-button {
            width: 100%;
            height: calc(56 * #{$coefficient});
            background: linear-gradient(90deg, #3E7DFA 0%, #5773FA 20%, #72C3FC 100%);
            border-radius: calc(8 * #{$coefficient});
            margin-top: calc(30 * #{$coefficient});
            text-align: center;
            line-height: calc(56 * #{$coefficient});
            font-size: calc(20 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;

            .el-icon-loading {
                margin-right: calc(6 * #{$coefficient});
            }
        }
        .login-button_loading {
            cursor: not-allowed;
            pointer-events: none;
            filter: opacity(30%);
            -webkit-filter: opacity(30%);
        }
    }
}
</style>
